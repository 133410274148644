<template>
  <div class="s-footer-navigation" :style="backgroundStyles">
    <div class="s-footer-navigation__container">
      <div class="s-footer-navigation__container_logo">
        <SalveImage url="/images/flowery-logo.png" alt="Flowery Logo" loading="lazy" />
      </div>
      <div class="s-footer-navigation__container_links">
        <PageFooterNavigationLinks />
        <PageFooterContactsWrapper />
      </div>
      <a class="text-white absolute right-0 top-1/2 transform -translate-y-1/2 p-[10px] bg-black" href="#"
        data-acsb-custom-trigger="true"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="w-[24px]"
          xml:space="preserve" viewBox="0 0 100 131.3" part="acsb-trigger-icon-svg" data-testid="base-icon-svg"
          style="display: inline-flex;">
          <path xmlns="http://www.w3.org/2000/svg"
            d="M71.6 131.3c1 0 2.1-.3 3.1-.8 3.9-1.8 5.5-6.2 3.6-10.1 0 0-14.3-32.7-16.9-44.7-1-4.2-1.6-15.3-1.8-20.5 0-1.8 1-3.4 2.6-3.9l32-9.6c3.9-1 6.2-5.5 5.2-9.4-1-3.9-5.5-6.2-9.4-5.2 0 0-29.6 9.6-40.3 9.6-10.4 0-39.8-9.4-39.8-9.4-3.9-1-8.3.8-9.6 4.7-1.3 4.2 1 8.6 5.2 9.6l32 9.6c1.6.5 2.9 2.1 2.6 3.9-.3 5.2-.8 16.4-1.8 20.5-2.6 12-16.9 44.7-16.9 44.7-1.8 3.9 0 8.3 3.6 10.1 1 .5 2.1.8 3.1.8 2.9 0 5.7-1.6 6.8-4.4l15.3-31.2L64.8 127c1.3 2.7 3.9 4.3 6.8 4.3z">
          </path>
          <circle xmlns="http://www.w3.org/2000/svg" cx="50.3" cy="14.6" r="14.6" style="fill:#fff"></circle>
        </svg></a>
    </div>
  </div>
</template>

<script>
import { imageData } from '@/utils/images'

export default {
  computed: {
    backgroundStyles() {
      const { path, provider } = imageData('/grunge-bg.jpg')
      return {
        backgroundImage: `url('${this.$img(path, { quality: 50 }, { provider })}')`
      }
    }
  }
}
</script>

<style scoped lang="postcss">
.s-footer-navigation {
  width: 100%;
  margin: 0 auto;
  background-size: cover;

  &__container {
    width: 1440px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    @apply pb-20;

    @media screen and (max-width: 1440px) {
      width: 100%;
    }

    @media screen and (max-width: 640px) {
      position: relative;

      @apply flex-col flex justify-center w-full items-center text-center;
    }

    &_links {
      display: flex;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    &_logo {
      width: 457px;

      @media screen and (max-width: 1024px) {
        width: 367px;
      }

      @media screen and (max-width: 640px) {
        top: inherit;
        width: 250px;
        left: inherit;
        @apply mb-10;
      }

      img {
        margin-left: 90px;
        margin-top: 108px;

        @media screen and (max-width: 1240px) {
          margin-left: 50px;
        }

        @media screen and (max-width: 1024px) {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
